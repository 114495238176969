.img1 {
  width: 100%;
  /* border-radius: 10%; */
  transition: transform 0.3s ease;
}

.p-3 {
  border-radius: 20px;
}

.p-3 {
  box-shadow: 10px 10px 20px black;
}

.row {
  margin-right: 0;
  margin-left: 0;
  overflow-x: hidden;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.col-md-6,
.col-lg-4 {
  padding-left: 0;
  padding-right: 0;
}

a:hover .bi-arrow-right {
  transition: 0.5s all ease-in-out;
  color: #8b48df;
}

a:hover {
  font-weight: bold;
  color: #8b48df;
}