
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.3s;  
  background-color: transparent;
}

header img {
  width: 70px;
}


.navbar {
  background-color: transparent;
  backdrop-filter: blur(50px);
}

.nav-item:hover {
  border-radius: 10px;
  background-color: rgba(255,255,255,0.2);

}


#header nav  {
  background-color: #7c7c7c70;
}

.full-height {
  height: 100vh !important;
}

.offcanvas-light {
  background-color: #ffffff; /* Light background color */
  color: #000000; /* Light text color */
}

.offcanvas-dark {
  background-color: #3faad780; /* Dark background color */
  color: #ffffff; /* Dark text color */
}



.switch {
  display: inline-block;
  position: relative;
}

.switch__input {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.switch__label {
  position: relative;
  display: inline-block;
  width: 60px;  /* Reduced from 120px */
  height: 30px; /* Reduced from 60px */
  background-color: #8FB5F5;
  border: 3px solid #347CF8; /* Reduced from 5px */
  border-radius: 9999px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(.46,.03,.52,.96);
}

.switch__indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-72%);
  display: block;
  width: 20px;  /* Reduced from 40px */
  height: 20px; /* Reduced from 40px */
  background-color: #ECD21F;
  border-radius: 9999px;
  box-shadow: none;
  transition: all 0.4s cubic-bezier(.46,.03,.52,.96);
}

.switch__decoration {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 10px;  /* Reduced from 20px */
  height: 10px; /* Reduced from 20px */
  background-color: #FFFFFF;
  border-radius: 9999px 9999px 0 0;
  animation: cloud 4s linear infinite; /* Reduced from 8s */
  
  &::before,
  &::after {
      position: absolute;
      display: block;
      content: '';
      background-color: #FFFFFF;
      border-radius: 9999px;
  }
  
  &::before {
      width: 5px;  /* Reduced from 10px */
      height: 5px; /* Reduced from 10px */
      bottom: 0;
      left: -4px;  /* Adjusted from -8px */
  }
  
  &::after {
      width: 7px;   /* Reduced from 15px */
      height: 7px;  /* Reduced from 15px */
      bottom: 0;
      left: 8px;    /* Adjusted from 16px */
      border-bottom-right-radius: 9999px;
  }
}

@keyframes cloud {
  0% {
      transform: translate(-20%, -50%);
  }
  50% {
      transform: translate(20%, -50%);
  }
  100% {
      transform: translate(-20%, -50%);
  }
}

.switch__input:checked + .switch__label {
  background-color: #2B2B2B;
  border-color: #5B5B5B;
  
  .switch__indicator {
      background-color: #7B7B7B;
      transform: translate(-50%, -50%) translateX(72%);
      box-shadow: 5px 0px 0 0 rgba(#000000, 0.2) inset; /* Reduced from 10px */
      
      &::before,
      &::after {
          display: block;
          background-color: #FFFFFF;
          border-radius: 9999px;
      }
      
      &::before {
          top: 4px;    /* Adjusted from 7px */
          left: 4px;   /* Adjusted from 7px */
          width: 4px;  /* Reduced from 9px */
          height: 4px; /* Reduced from 9px */
          opacity: 0.6;
      }
      
      &::after {
          bottom: 4px;  /* Adjusted from 8px */
          right: 3px;   /* Adjusted from 6px */
          width: 6px;   /* Reduced from 14px */
          height: 6px;  /* Reduced from 14px */
          opacity: 0.8;
      }
  }
  
  .switch__decoration {
      top: 65%;
      width: 3px;   /* Reduced from 5px */
      height: 3px;  /* Reduced from 5px */
      animation: twinkle 1.2s infinite;
      
      &::before {
          top: -10px;  /* Adjusted from -20px */
          left: 5px;   /* Adjusted from 10px */
          width: 3px;  /* Reduced from 5px */
          height: 3px; /* Reduced from 5px */
          opacity: 1;
          animation: twinkle 1.2s infinite 0.4s;
      }
      
      &::after {
          top: -4px;   /* Adjusted from -7px */
          left: 15px;  /* Adjusted from 30px */
          width: 3px;  /* Reduced from 5px */
          height: 3px; /* Reduced from 5px */
          animation: twinkle 1.2s infinite 0.8s;
      }
  }
}

@keyframes twinkle {
  0%, 100% { 
      opacity: 1; 
  }
  50% { 
      opacity: 0.2; 
  }
}